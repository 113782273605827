import React, { Component } from 'react';


/**
 * @typedef {object} props
 * @prop {number} index
 * @prop {number} length
 * @extends {Component<Props>}
 */
export default class Progress extends Component {

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps);
        }
    }

    render() {
        let balls = []
        let {index, length} = this.props
        
        for (let i = 0; i < length; i++) {
            if (i <= index) {
                balls.push({status: true, index: i + 1})
            } else {
                balls.push({status: false, index: i + 1})
            }
        }

        return (
            <div className = "row col-12 progress-container">
             {balls.map(
                ball => ball.status ?

                // Bolinha ativa
                    <div key={ball.index} className="row marg ">
                        {ball.index == 1 ? <></> : <div className=" line1"/>}
                        <div key={ball.index} className = "ball active" >
                            {ball.index}
                        </div>
                        
                    </div>
                : 
                // Bolinha inativa
                    <div key={ball.index} className="row marg">
                        {ball.index == 1 ? <></> : <div className="line"/>}
                        <div  className = "ball" >
                            {ball.index}
                        </div>
                       
                    </div>  
            )}
            </div>
        )
    }
}

