const url = "http://127.0.0.1:3030"

const requests = {
    "acuity": url + "/acuity/?",
    "allPatient": url + "/psyco-patient?",
    "patient": url + "/psyco-patient/",
    "create":  url + "/api/psyco-patient",
    "initial": url + "/api/psyco-patient/initial-ci",
    "detail": url + "/api/psyco-patient/detail-ci",
    "getPatient": url + "/api/psyco-patient/",
    "retryAppoint": url + "/acuity/retryAppoint",
    "login": url + "/authentication"
}

const request = async (method: "GET"|"POST"|"PUT",url: "login"|"acuity"|"allPatient"|"patient"|"create"|"initial"|"getPatient"|"detail"|"retryAppoint", queryParams: any,body: object, id: String) => {
    try {
        let reqUrl = requests[url] + (id ? id : new URLSearchParams(queryParams))
        if (!reqUrl) return

        let authorization = localStorage.getItem('API_TOKEN')
        
        let _headers:any = authorization ? 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': authorization
            } :
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
            
        let resp = await fetch(reqUrl, {
            method,
            body: body ? JSON.stringify(body) : null,
            headers: _headers
        })
        
        if (resp.status >= 200 && resp.status < 300)
            return  {"ok": true, "data": await resp.json()}
        else
            return {"ok": false, "data": await resp.text()}
    } catch (ex) {
        console.log(ex)
        return {"ok": false, "data": ex.toString()}
    }
}

export default request