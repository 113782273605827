import React, {Component} from 'react';

import './App.css'

import LogoEurekka from "../assets/logo.png"
import LogoNav from "../assets/logo-nav.png"
import request from '../util/middleware';
import { browserHistory } from 'react-router-dom';

export default class Login extends Component{
    
    constructor(){
        super();
        this.state=({ 
            db:[],
            frase:[], 
            logged:false,
            email: "",
            password: ""

        });        
    }


    login = async ()=> {
        const data = {
            "email" : this.state.email,
            "password" : this.state.password,
            "strategy": "local"
          }

        let resp = await request('POST', 'login', '', data)
        
        if (resp.ok && resp.data.accessToken) {

            alert("Vou salvar no campo accessToken o token" + resp.data.accessToken)
            localStorage.setItem('API_TOKEN', resp.data.accessToken)
            
            this.props.history.replace({ pathname: '/dashboard'})

        }
        else {
            //TODO:Mostrar para o usuario q as credenciais estao invalidadas
            alert(JSON.stringify(resp.data))
        }
    }

    setEmail=(value)=>{

        this.setState({
            email:value
        })
    }

    setPassword=(value)=>{

        this.setState({
            password:value
        })
    }

    render(){
        return(
            <div className="bg" >
                <div className="nav-menu">
                    <img className= "img-nav" src={LogoNav }/>
                </div>
                
                <div className="container">
                        <div className="blackCard container">  
                        <img className= "imagem" src={LogoEurekka}/>
                        <input
                            className = "input in"
                            placeholder = "E-mail"
                            value = {this.state.email}
                            onChange = {(evt) => this.setEmail(evt.target.value)}/>
                        <br></br>

                        <input
                            type="password"
                            className = "input in"
                            placeholder = "Senha"
                            value = {this.state.password}
                            onChange = {(evt) => this.setPassword(evt.target.value)}/>
                        <br></br>
                        <button className = "btnE" onClick = {this.login}>Login </button></div>
                        
                </div> 
            </div>
        );
    }
}