import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Dashboard from './components/dashboard'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Redirect
} from "react-router-dom";

import Calendar from './components/ci_initial';
import CIDetailWizard from './components/ci_detail'

import Login from './components/login'

import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <Router>
    <Switch>

      <Route exact path = "/">
        <Redirect to = "/ci-initial/default"/>
      </Route>


      <Route exact path="/ci-initial/:source" component = {Calendar}>
      </Route>

      <Route exact path="/ci-initial/token/:token" render = {(props) => <Calendar {...props} fromToken = {true}/>}>
      </Route>

      <Route path = "/ci-detail/:token" component = {CIDetailWizard}>
      </Route>

      <Route path="/login" render = {(props) => <Login {...props}/>}> 
      </Route>
      
      <Route path="/dashboard">
        <Dashboard/>
      </Route>

    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function <App />
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
