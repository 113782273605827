import React, { Component } from 'react';
import Ch from '../assets/check.png'


export default class CheckPage extends Component {

        constructor(props){
          super(props);
      
          this.state = {
            currentTime: 7
          }
        }

        tick(){
            this.setState((prevState, props) => ({
              currentTime: prevState.currentTime == 0 ?  0 : prevState.currentTime - 1
            }));
        }
      
        
        componentDidMount(){
          /*atributo timerID invoca a função setInterval, que chama a função tick()
          a cada 1000ms (1 segundo)*/
          this.timerID = setInterval(
            (prevState, props) => this.tick(),
            1000
          );
        }
      
      


  render() {
    return (
      <>
       <div className="col-12 justify-content-center d-flex thanksPage row ">
         <div className="col-12 d-flex justify-content-center ">
           <img className= "imgthankslg" src={Ch}/>
         </div>
         <div className=" col-12 d-flex justify-content-center row mt-5">
         
            <h4 className="col-12"> Você será redirecionado para a página de pagamento em {this.state.currentTime} segundos!!</h4>
            
            <h6 className="col-12 d-flex justify-content-center ">Caso não tenha sido redirecionado, é só clicar no link que te enviaremos por e-mail.</h6> 
            <h2 className="col-12 d-flex justify-content-center ">Muito obrigado</h2> 
            
            
           
         </div>
                        
       </div>
       
      </>
    );
  }
}
