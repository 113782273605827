import React, { Component } from 'react';
import moment from 'moment'
import request from '../util/middleware'

import Logo from '../assets/logo.png'
import Right from "../assets/right.png"
import Left from "../assets/left.png"
import Bg from "../assets/bg.png"

import Calendar from 'react-calendar'

/**
 * @typedef {object} props
 * @prop {List} months
 * @prop {Function} handlePickDay
 * 
 * @extends {Component<Props>}
 */
export default class DatePage extends Component {


    constructor(props) {
        super(props)
        this.state = {
            active: new Date(),
            availableDays: []
        }
    }

    componentDidMount(){
        this.fetchAvailableDays()
    }

    fetchAvailableDays = async (props) => {

        if (props) {
            this.setState({active: props.activeStartDate})
        }

        this.setState({loading: true})

        let requestDate = props ? props.activeStartDate : this.state.active
        let formatDate = moment(requestDate).format('YYYY-MM')

        let params = {
            unit: "days",
            time: formatDate,
        }

        let resp = await request('GET', 'acuity', params)
        
        let available = []
        let state = this.state
        state.availableDaysWithMedics = resp.data
        
        for (let obj of resp.data) {
            for (let d of obj.date) {
                available.push(d)
            }
        }


        
        state.availableDays = available
        state.loading = false 

        this.setState(state)
    }


    getClassName = (args) => {
        let {_, date, view} = args

        let currentDate =  moment(date)

        let isAvailable = false
        for (let ad of this.state.availableDays) {
            ad = moment(ad.date)
            
            // console.log('BOA! BOA! Estou comparando ')
            if(moment(currentDate).isSame(ad)) {
                isAvailable = true
            }   
        }

        return isAvailable ? "mincard" : "mincard-d"
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps);
        }
    }


    render() {
        console.log('Vou renderizar o date page')
        return (
            <>
            <div className="container ">
                    <div className="whiteCard">  
                    <div className="col-12 d-flex justify-content-center" >
                    <img className="bgimg" src={Bg }/>  
                    </div>
                    
                    <h4 className="col-12 d-flex justify-content-center">Qual o melhor dia para nossa conversa inicial? </h4>
                    <h6 className="col-12 d-flex justify-content-center">Selecione no calendário</h6>
                    
                    
                        <div className="yellowCard row">
                        {this.state.loading? 
                        
                        <img className= "imagem App-logo" src={Logo}/>:
                        <Calendar
                            locale = 'PT-BR'
                            value = {this.state.active}
                            className = "calendar"
                            view = "week"    
                            showNavigation = {true}
                            navigationLabel	= {(a) => <h3 className = "title">{this.props.months[a.date.getMonth()]}</h3>}
                            nextLabel = {<img className="seta" src={Right}/>}
                            
                            prevLabel = {<img className="seta" src={Left}/>}
                            nextAriaLabel = {<div className = "invisible"/>}
                            prevAriaLabel = {<div className = "invisible"/>}
                            onActiveStartDateChange = {(props) => this.fetchAvailableDays(props)}
                            prev2AriaLabel = {<div className = "invisible"/>}
                            next2AriaLabel = {<div className = "invisible"/>}
                            next2Label = {<div className = "invisible"></div>}
                            prev2Label = {<div className = "invisible"/>}
                            
                            calendarType = 'ISO 8601'
                            showNeighboringMonth = {false}
                            onClickDay = {this.props.handlePickDay}
                            tileClassName = {this.getClassName}

                        />}
                        </div>
                        <br></br>  
                    </div>
                    <img className="bg-img" src={Bg }/>  
               </div> 
                <br></br>
                </>
        )
    }
}
