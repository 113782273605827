import React, {Component} from 'react';

import './App.css'

import moment from 'moment'

import LogoNav from "../assets/logo-nav.png"

import request from '../util/middleware'

import AppointmentPage from './appointment_page'
import HourPage from './hour_page'
import DatePage from './date_page'
import Progress from './progress_indicator'
import CheckPage from './checkout'

export default class App extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            currentPage: 0,
            token: '',
            availableDays: [],
            date: "",
            day: "",
            hour: "",
            specialty: "",
            loading: false,
            hours: [],
            name: "",
            phone: "",
            fullName: "",
            ciEmail: "",
            country: "BR",
            ddi: "+55"

        };
        
    }

    fromToken = () => this.props.match.params.token

    componentDidMount() {
        // Checando se é um usuário que veio de um problema do agendamento
        if (this.fromToken()) {
            this.fetchUser()
        } else {
            this.createUser()
        }

    }

    fetchUser = async () => {
        
        let token = this.fromToken()
        let resp = await request('GET', 'patient', '', null, token)
        

        if (resp.ok) {
            this.setState({
                token: token,
                fullName: resp.data.fullName,
                email: resp.data.email,
                phone: resp.data.phone,
            })
        } else {
            
        }
    }

    createUser = async () => {
        try{
            let source = this.props.match.params.source
            let resp = await request('POST', 'patient', '', {source})
            
            
            let user =  resp.ok ? resp.data : resp.data
            let token = user["_id"]

            
            this.setState({token})
        } catch(ex) {
            alert(ex)    
        }
    }

    changeText = (value, index)=>{
        this.setState({
            [index]:value
        })
    }

    fetchAvailableHours = async () => {

        this.setState({
            loading:true
        })

        let date = moment(this.state.date).format('YYYY-MM-DD')
        let params = {
            unit: "hours",
            time: date,
        }

        let resp = await request('GET', 'acuity', params)

        // Vamos lá!

        let medicDatesTupleArray = resp.data
        let aHours = []

        // Para cada tupla medico/data
        for (let md of medicDatesTupleArray) {
            
            // Adicionamos a data às horas disponíveis, atrelando aos ids do médico
            for (let date of md.date) {

                let hourWithID = date
                hourWithID['appointmentTypeId'] = md.ids.appointmentTypeId
                hourWithID['calendarId'] = md.ids.calendarId
                aHours.push(hourWithID)
            }
        }

        // Unificando o array em datas unicas
        let uniqueAHourArray = []
        for(let aHour of aHours) {
            let found = false
            
            // Para cada item das horas unicas
            for(let item of uniqueAHourArray) {
                if (item.time == aHour.time) {
                    found = true
                    item.ids.push({calendarId: aHour.calendarId, appointmentTypeId: aHour.appointmentTypeId})
                }
            }

            if (!found) {
                let uniqueAHour = aHour
                uniqueAHour.ids = [{calendarId: aHour.calendarId, appointmentTypeId: aHour.appointmentTypeId}]
                uniqueAHourArray.push(uniqueAHour)
            }
        }

        // Ordenando pela hora
        uniqueAHourArray = uniqueAHourArray.sort(
            (a, b) => moment(a.time).isBefore(b.time) ? -1 : 1
        )

        // Abordagem com reduce para mentes brilhantes, não é o nosso caso
        
        // aHours.reduce((newArray, aHour, ci) => {
        //     if (newArray.includes(aHour))
        // }, [])
        
        if (resp.ok) {
            this.setState({
                hours: uniqueAHourArray,
                loading: false
            })
        } else {
            this.setState({
                erro: resp.data,
                loading: false
            })
        }
    }

    handlePickSpecialty = (specialty) => {
        let nextPage = this.state.currentPage + 1
        this.setState({
            currentPage: nextPage,
        })
    }

    handlePickDay = (d) => {

        let state = this.state
        // alert(d)
        state.date = d
        state.currentPage = state.currentPage + 1

        this.setState(state)
        
        // Chamada para API buscando as horas vagas
        this.fetchAvailableHours()
    }

    handlePickHour = (hour) => {
        let nextPage = this.state.currentPage + 1
        alert(JSON.stringify(hour))
        this.setState({
            currentPage: nextPage,
            hour: hour
        })
    }

    months = {
        0: "Janeiro",
        1: "Fevereiro",
        2: "Março",
        3: "Abril",
        4: "Maio",
        5: "Junho",
        6: "Julho",
        7: "Agosto",
        8: "Setembro",
        9: "Outubro",
        10: "Novembro",
        11: "Dezembro"
    }

    specialtys = [
        'Ginecologia',
        'Psiquiatria',
        'Neurologia',
        'Nutricionista',
        'Personal Trainer',
        'Infectologia',
        'Pediatria',
        'Dermatologia',
        'Clínica Geral',
        'Teste'
    ]


    goToCalendar = () => {
        this.setState({
            currentPage: 0
        })
    }

    getAppointmentDate = () => {
        // Data de agendamento
        let dateHour = new moment(this.state.hour.time)
        let hour = moment(dateHour).hour()
        let minute = moment(dateHour).minute()
        let appointmentDate = moment(this.state.date).set('minute', minute).set('hour', hour).format()
        return appointmentDate
    }

    changePage = async (previous) => {
        
        let nextPage = this.state.currentPage + (previous ?  -1 : 1)
        
        if(nextPage === this.pages.length - 1) {

            if (this.fromToken()) {

                let token = this.fromToken()
                let resp = await request('POST', 'retryAppoint', '', {
                    date: this.getAppointmentDate(),
                    token: token,
                    possibleAppointmentIds: this.state.hour.ids
                })

                if (resp.ok)
                    window.location.replace("http://localhost:3000/ci-detail/"+token)
                else 
                    alert (resp.data)
                return
            }

            else
                this.goToCheckout()
        }

        this.setState({
            currentPage: nextPage
        })
    }

    goToCheckout = async () => {

        const sleep = (ms) => new Promise(resolve => setTimeout(() => {resolve(true)}, ms))
        

        // Se ele já passou pelo checkout
        if (this.fromToken()) {
            let token = this.fromToken()
            
            // TODO: Agendar
            window.location.replace("http//localhost:3000/ci-detail/"+token)
            return                                                  
        }

        // [{ids1, ids2, ids3}]

        // Salvando dados iniciais                                                                  
        const body = {
            fullName: this.state.fullName,
            email: this.state.email,
            phone: this.state.ddi + this.state.phone,
            specialty: this.state.specialty,
            possibleAppointmentIds: this.state.hour.ids,
            date: this.getAppointmentDate()
        }

        let resp = await request('PUT', 'patient', '', body, this.state.token)

        await sleep(5000)
        
        if(resp.ok) {

            // Ir para o checkout
            let token = this.state.token
            window.location.replace("http://localhost:5000/"+token);
        } else {
            alert(resp.data)
        }
    }

    pages = [

        () => <DatePage
            months = {this.months}
            handlePickDay = {this.handlePickDay}
            specialty = {this.state.specialty}

        />,
        () => <HourPage
            date = {this.state.date}
            hours = {this.state.hours}
            erro = {this.state.erro}
            months = {this.months}
            loading = {this.state.loading}
            handlePickHour = {this.handlePickHour}
            goToCalendar = {this.goToCalendar}
            changePage = {this.changePage}
        />,
        () => <AppointmentPage
            changeText = {this.changeText}
            state = {this.state}
            date = {this.state.date}
            hour = {this.state.hour}
            months = {this.months}
            changePage = {this.changePage}
            goToCalendar = {this.goToCalendar}
        />,

        () => <CheckPage/>
    ]

    render(){
        return(
            <div className="bg2" >

                <div className="nav-menu d-flex justify-content-center">
                <div className= "logo"> <img  href="https://eurekka.me/terapia" className= "img-nav" src={LogoNav}/> </div>   
                
                </div>
                <div className="container">
                    {
                    this.state.currentPage <= 3 || this.state.currentPage + 1 == this.pages.length?     
                    <></>
                    :
                    <Progress index = {this.state.currentPage - 4} length = {7}/>
                    }
                
                    {this.pages[this.state.currentPage]()}

                </div>
            </div>
        );
    }
}