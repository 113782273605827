import React, { Component } from 'react';
import moment from 'moment'

import {validateName, validatePhone, validateEmail} from '../util/validations.ts'

import InputMask from 'react-input-mask';
import ReactFlagsSelect, {
    Br,
    Us,
    Gb,
    Fr,
    De,
    It,
    Ch,
    Cl
} from 'react-flags-select';

/**
 * @typedef {object} props
 * @prop {Date} date
 * @prop {Hour} hour
 * @prop {Func} changeText
 * @prop {object} state
 * @prop {List} months
 * @prop {Fuction} nextPage
 * 
 * @extends {Component<Props>}
 */

import Cv from '../assets/cv.png'

export default class Appointment_page extends Component {
    constructor(props) {
        super(props);
        
        // Estado só armazena se deu erro ou não na validação
        this.state = {
            name: false,
            email: false,
            phone: false
        };
        
    }

    validations = {
        "email": validateEmail,
        "phone": validatePhone,
        "name": validateName
    }

    getAppoitmentDate = () => {
        try {
            let date = new moment(this.props.date.toString())
            let dateHour = new moment(this.props.hour.time)
            
            let hour = moment(dateHour).hour()
            let minute = moment(dateHour).minute()
    
            let month = moment(date).month()
            date = moment(date).hour(hour).minute(minute).format('DD [de] [' + this.props.months[month] + '] à[s] HH:mm')
            return date.toString() + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'
        } catch (ex) {
            return ex.toString()
        }
    }

    ddiMap = {
        "+1": Us,
        "+44": Gb,
        "+33": Fr,
        "+49": De,
        "+39": It,
        "+55": Br,
        "+41": Ch,
        "+56": Cl
    }

    appoint = () => {

        // Retorna falso caso dê certo e uma string com a msg de erro caso de errado
        let name = validateName(this.props.state.fullName)
        let email = validateEmail(this.props.state.email)
        let phone = validatePhone(this.props.state.phone)
        
        console.log({name, email, phone})
        if (!name && !email && !phone){
            console.log("Estou caindo aqui")
            this.props.changePage()
        }
        else {
            let state = this.state
            state.name = name
            state.email = email
            state.phone = phone
            this.setState(state)
        }
    }

    errorLabel = (index) => {
        
        let error = this.state[index]
        console.log(error)
        return (
            <div className = "col-12 col-lg-9" >
                {error ? <div style = {{color: 'red', fontSize: 12, marginRight: '1vw'}}>{error}</div> : <></>}
            </div>
        )
    }

    render() {
        return (
            <div className="container">    
                    <div className="whiteCard container">  
                    
                        <div className="row col-12 pcard">

                        <div className="col-12   mobile">
                        <img className= "imgCi" src={Cv}/>
                        </div>

                        <div className="col-12 row mobile">
                                <h6 className="col-12"> Seu encontro com a eurekka será dia {this.getAppoitmentDate()}</h6>
                                
                            </div>
                            <h4 className = "col-12 d-flex justify-content-center">Vamos confirmar nossa conversa?</h4><br></br>
                            
                            <div className="col-12 col-lg-6 confcard borderline">
                            <img className= "imgStream" src={Cv}/>
                            <br></br>
                                <h6>Seu encontro com a Eurekka será dia</h6>
                                <h6>{this.getAppoitmentDate()}</h6>
                            
                            </div>
                            <div className="col-12 col-lg-6 confcard">
                                
                                <h6 ><strong>Dados Pessoais</strong></h6>
                                
                                {this.errorLabel('name')}
                                <input  className = "input col-12 col-lg-8 inConf" placeholder = "Nome Completo" value = {this.props.state.fullName} onChange = {(evt) => this.props.changeText(evt.target.value, 'fullName')}/>
                                
                                {this.errorLabel('email')}
                                <input  className = "input col-12 col-lg-8 inConf" placeholder = "E-mail" value = {this.props.state.email} onChange = {(evt) => this.props.changeText(evt.target.value, 'email')}/>
                                
                                {this.errorLabel('phone')}

                                <div className="direction col-lg-8 col-12">
                                        {this.ddiMap[this.props.state.ddi] ? this.ddiMap[this.props.state.ddi]() : <div style = {{height: '1px'}}></div>}
                                        <input
                                            className = "input col-3 col-lg-3 inConf"
                                            placeholder = "DDI"
                                            onChange = {(evt) => {
                                                this.props.changeText(evt.target.value, 'ddi')
                                            }}
                                            value = {this.props.state.ddi}
                                        /> 
                                        <InputMask
                                            placeholder = "Telefone"
                                            className = "input col-7 col-lg-7 inConf"
                                            mask = {'(99) 99999-9999'}
                                            value = {this.props.state['phone'] || ""}
                                            onChange = {(evt) => this.props.changeText(evt.target.value, 'phone')}
                                        />
                                    </div>
                        
                            </div>
                            
                            <button onClick = {() => this.props.changePage(true)} className = " mobnone btnC">Voltar </button>
                            <button onClick = {this.appoint} className = "  btnC">Agendar </button>
                            
                            <button onClick = {() => this.props.changePage(true)} className = " desknone btnC">Voltar </button>
                        </div>
                            
                    </div>
            </div>
        )
    }
}
