import React, { Component } from 'react';
import html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import request from '../util/middleware';
import moment from 'moment'

import './dashboard.css'

export default class Dashboard extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
            patients: [],
            totalPatientsInDataBase: 0, 
            isAuth: false,
            skip: 0,
        }
    }

    PATIENT_COUNT_PER_PAGE = 20

    fetchPatients = async () => {

        let params = {
            "\$limit": this.PATIENT_COUNT_PER_PAGE,
            "\$skip": this.PATIENT_COUNT_PER_PAGE * this.state.skip,
            "\$sort[createdAt]": "-1"
        }
        let resp = await request('GET', 'allPatient', params)
        if (resp.ok)
            this.setState({
                patients: resp.data.data,
                totalPatientsInDataBase: resp.data.total,
                isAuth: true
            })
        else 
            this.setState({
                isAuth: false
            })
    }

    componentDidMount() {
        this.fetchPatients()
    }


    downloadPdf = (id, fileName) => {
        const input = document.getElementById(id);
        input.style.display = "block"
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm');
            console.log(imgData)
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(fileName + ".pdf");

        })

        input.style.display = 'none'
    }

    info = (value, big) => {
        let className = big ? "dashboard-info big" : "dashboard-info"
        return(
            <div className = {className}>
                <span>
                    {value}
                </span>
            </div>
        )
    }

    infoIndex = (name) => {
        let className = name == "E-mail" ? "dashboard-info name no-select big" : "dashboard-info name no-select"
        return <div className = {className}>
            {name}
        </div>
    }

    formatPhone = (phone) => {
        return phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
    }


    viewText = (id) => {
        const input = document.getElementById(id);
        input.style.display = input.style.display == "block" ? "none" : "block"
    }

    formatStatus = (status) => {
        if (status === true ) return "Agendado"
        if (status === false) return "Não agendado"
        if (status == "order.paid") return "pago"
        if (status == "inicial") return "Não pagou"
        return status
    }

    row = (user, index) => {
        let divId = "print" + index.toString()
        
        // Filtrando aqueles que não chegaram a fazer nada
        if (!user.email) return <></>
        return(
            <>
            <div className = "dashboard-row">
            
                <div className = "dashboard-user-row">

                    {this.info(user.fullName)}
                    {this.info(user.email, true)}
                    {this.info(user.phone)}

                    {/* TODO: Mapeamento do estado anterior*/}
                    {this.info(this.formatStatus(user.appointmentStatus))}
                    {this.info(this.formatStatus(user.paymentStatus))}
                    {this.info(moment(user.createdAt).format('DD/MM à[s] HH:mm'))}
                    {this.info(moment(user.date).format('DD/MM à[s] HH:mm'))}

                    <a style = {{color: "black"}} href= {"https://wa.me/" + this.formatPhone(user.phone)}>
                        📞
                    </a>
    
                </div>
                
                <button className = "no-select dashboard-button" onClick = {() => this.downloadPdf(divId, user.fullName)}>
                    📄
                </button>

                <button className = "no-select dashboard-button" onClick = {() => this.viewText(divId)}>
                    👁
                </button>
            
            </div>

            <div className = "to-print" id = {divId}>
                <div>
                    {Object.entries(user).map(
                        ([key, value]) => <div>{key + ' --> ' + value} </div>
                    )}
                </div>
            </div>

           
            </>
        )
    }

    previousPage = () => {

        let state = this.state
        state.skip-- 
        this.setState(state)

        this.fetchPatients()
    }

    nextPage = () => {
        let state = this.state
        state.skip++ 
        this.setState(state)

        this.fetchPatients()
    }

    getIndexesWithCurrentHighlighted = () => {
        let indexes = []
        let indexCounter = 1
        let maximumNumberOfPages = (this.state.totalPatientsInDataBase / this.PATIENT_COUNT_PER_PAGE)
        
        for(let i = this.state.skip; i >= this.state.skip - 4 && i >= 0; i --) {
            
            let isCurrentIndex = i == this.state.skip

            indexes.push(
                <div style = {isCurrentIndex ? {fontWeight: 'bold'} : {}}>
                    {i + 1}
                </div>
            )
        }
        indexes = indexes.reverse()
        for(let i = this.state.skip + 1; i <= this.state.skip + 3 && i < maximumNumberOfPages; i ++) {
            indexes.push(<div>{i + 1}</div>)
        }

    
        return indexes
    }
    
    render() {
        if (this.state.isAuth == false) return <div>Não autorizado</div>

        let isFirstPage = this.state.skip == 0
        
        let isLastPage = this.state.totalPatientsInDataBase - (this.PATIENT_COUNT_PER_PAGE * this.state.skip) <= this.PATIENT_COUNT_PER_PAGE
        return (
              <div className = "dashboard-body">
                
                <h2>
                    Pacientes
                </h2>

                <div className = "dashboard-user-row">
                    {this.infoIndex("Nome")}
                    {this.infoIndex("E-mail")}
                    {this.infoIndex("Telefone")}
                    {this.infoIndex("Agendamento")}
                    {this.infoIndex("Pagamento")}
                    {this.infoIndex("Criado em")}
                    {this.infoIndex("Consulta")}
                </div>

                {this.state.patients.map(
                    (user, index) => this.row(user, index)
                )}


                <div className = "row d-flex justify-content-between" style = {{width: "50vw", alignSelf: 'center', paddingBottom: "5vh"}}>
                    
                    <button
                        onClick = {this.previousPage}
                        className = "btn btn-success"
                        disabled = {isFirstPage}
                    >
                        Anterior
                    </button>

                    {this.getIndexesWithCurrentHighlighted()}

                    <button
                        onClick = {this.nextPage}
                        className = "btn btn-success"
                        disabled = {isLastPage}
                    >
                        Próxima
                    </button>
                </div>

            </div>
        );
    }
}
