import React, { Component } from 'react';

import CIDetailPage from './ci_detail_page'
import CI from '../assets/CI.png'
import CI2 from '../assets/CI2.png'
import CI3 from '../assets/CI3.png'
import Cv from "../assets/cv.png"
import Social from '../assets/social.png'
import ThanksPage from './thanks'
import Progress from './progress_indicator'
import LogoNav from '../assets/logo-nav.png'
import request from '../util/middleware';
import AppointmentGoneWrong from './appointment_gone_wrong';

export default class CIDetailWizard extends Component {
    
    state = {
        currentPage: 0,
        error: false,

        ciEmail: '',
        cpf: '',
        birthday: '',
        zip: '',
        street: '',
        city: '',
        state: '',
        birthCity: '',
        occupation: '',
        civilStatus: '',
        contactName: '',
        contactRelation: '',
        contactPhone: '',
        psycoStatus: '',
        psycoName: '',
        psycoPhone: '',
        coments: '',
        sourceEurekka: '',
        sourceDetails: '',
        sourceIndication: '',

    }

    componentDidMount() {
        this.fetchUser()
    }

    fetchUser = async () => {

        let token = this.props.match.params.token
        let resp = await request('GET', 'patient', '', null, token)
        
        if (resp.ok) {
            
            let patient = resp.data
            
            // Verificando se o checkout deu certo mas o agendamento no Acuity não
            if ( patient['appointmentStatus'] === false) {
                this.setState({
                    error: true,   
                })
                return
            }

            this.setState({
                token: token,
                fullName: patient['fullName'],
                ciEmail: patient['ciEmail'],
                phone: patient['phone'],
                cpf: patient['cpf'],
                street: patient['street'],
                city: patient['city'],
                state: patient['state'],
                zip: patient['zip'],
            })
        }
    }

    changePage = (previous) => {
        
        let nextPage = this.state.currentPage + (previous ?  -1 : 1)
        
        // Checando se é a penultima (antes da página de obrigado)
        if (nextPage == this.pages.length - 1) {
            this.saveData()
        }

        this.setState({
            currentPage: nextPage
        })    
    }


    saveData = async () => {

        let token = this.props.match.params.token
        let body = this.state
        delete body.currentPage

        let resp = await request('PUT', 'patient', '', body, token)
        alert(resp)
    }

    changeText = (value, index)=>{
        this.setState({
            [index]:value
        })
    }

    pages = [
        () => <CIDetailPage
            title = "Vamos confirmar seus dados pessoais?"
            subtitle = "Lembre-se, os dados devem ser do paciente!"
            image = {CI}
            state = {this.state}
            first
            inputs = {[
                {label: "Nome Completo", name: "fullName" ,validation: (value) => {
                    if (value === 'batata') return "Você não pode chamar batata"
                }},
                {label: "E-mail", name: "ciEmail", readonly: true},
                {label: "Telefone", name: "phone", mask: "(99) 99999-9999"},
                {label: "CPF", name: "cpf", mask: "999.999.999-99"}
            ]}
            changeText = {this.changeText}
            changePage = {this.changePage}
        />,

        () => <CIDetailPage
            title = "Este é o seu endereço atual?"
            
            image = {CI}
            state = {this.state}
            inputs = {[
                {label: "CEP", name: "zip", mask: "99999-999"},
                {label: "Endereço", name: "street"},
                {label: "Cidade", name: "city"},
                {label: "Estado", name: "state"}

            ]}
            changeText = {this.changeText}
            changePage = {this.changePage}
        />,

        () => <CIDetailPage
            title = "Conte-nos um pouco mais sobre você..."
            subtitle = "Lembre-se, os dados devem ser do paciente!"
            image = {Cv}
            state = {this.state}
            inputs = {[
                {label: "Cidade onde nasceu", name: "birthCity",},
                {label: "Profissão/Ocupação", name: "occupation",},
                {label: "Data Nascimento", name: "birthday", mask: "99/99/9999"},
                {label: "Estado Civil", name: "civilStatus",}
            ]}
            changeText = {this.changeText}
            changePage = {this.changePage}
        />,

        () => <CIDetailPage
            title = "Precisamos de um contato de confiança..."
            subtitle = "Fique tranquilo, entraremos em contato apenas em situações de risco à sua vida."
            image = {CI2}
            state = {this.state}
            inputs = {[
                {label: "Nome", name: "contactName",},
                {label: "Qual sua relação (pai, mãe, amigo, colega)", name: "contactRelation",},
                {label: "Telefone para contato", name: "contactPhone", mask: "99 99999-9999"},
            ]}
            changeText = {this.changeText}
            changePage = {this.changePage}
        />,

        () => <CIDetailPage
            title = "Como é sua relação com a terapia?"
            image = {CI3}
            state = {this.state}
            inputs = {[
                {label: "Atualmente, você faz acompanhamento psiquiátrico", name: "psycoStatus", options: ['Sim', 'Não']},
                {label: "Qual nome do profissional?", name: "psycoName", condition: () => this.state.psycoStatus === "Sim"},
                {label: "Telefone do profissional", name: "psycoPhone", condition: () => this.state.psycoStatus === "Sim", mask: "99 99999-9999"},
                {label: "Tem algo que queira nos dizer?", name: "coments"},
            ]}
            changeText = {this.changeText}
            changePage = {this.changePage}
        />,

        () => <CIDetailPage
            title = "Como você conheceu a Eurekka?"
            subtitle = "Nos conte sobre sua experiência"
            image = {Social}
            state = {this.state}
            last
            inputs = {[
                {label: "Como conheceu a terapia da eurekka?", name: "sourceEurekka", options: [
                    'Google', 'Facebook', 'Instagram', 'Indicação', 'Outros'
                ]},
                {label: "Nos conte um pouco sobre", name: "otherAbout", condition: () => this.state.sourceEurekka === "Outros"},
                {label: "Qual nome de quem te indicou", name: "sourceIndication", condition: () => this.state.sourceEurekka === "Indicação"},
            ]}
            changeText = {this.changeText}
            changePage = {this.changePage}
        />,

        () => <ThanksPage/>
    ]
    
    render() {
        let token = this.props.match.params.token
        return (
            <div className="bg2" >

                <div className="nav-menu d-flex justify-content-center">
                <div className= "logo"> <img  href="https://eurekka.me/terapia" className= "img-nav" src={LogoNav}/> </div>   

                </div>
                <div className="container">

                    {this.state.error ?

                    <AppointmentGoneWrong token = {token}/> : 
                    
                    <>
                    {this.state.currentPage === this.pages.length - 1 ? <></> : <Progress index = {this.state.currentPage} length = {this.pages.length}/>  }
                    
                    {this.pages[this.state.currentPage]()}
                    </>
                    }

                </div>
            </div>
        );
    }
}
