import React, { Component } from 'react';
import moment from 'moment'

import Bg from "../assets/bg.png"
import Logo from "../assets/logo.png"

/**
 * @typedef {object} props
 * @prop {Date} date
 * @prop {List} hours 
 * @prop {List} months
 * @prop {object} state
 * @prop {Function} handlePickHour
 * @prop {String} erro
 * @prop {Boolean} loading
 * 
 * @extends {Component<Props>}
 */

export default class HourPage extends Component {

    getDay = () => {
        try {
            return this.props.date.getDate() + ' de ' + this.props.months[this.props.date.getMonth() ] 
        } catch (ex) {
            return "erro"
        }
    }

    render () {
        return (
        <div className="container">
            
            <div className="whiteCard"> 
            <div className="col-12 d-flex justify-content-center" >
                    <img className="bgimg" src={Bg }/>  
                    </div>
            <br></br> 
            <h4 className="col-12 d-flex justify-content-center">Qual o melhor horário para nossa conversa inicial?</h4>
            <h7 className="col-12 d-flex justify-content-center">Selecione abaixo</h7>
            <br></br>
                    
                    <div className="row">
                                
                                

                                <div className="yellowCard p-5 time container">
                                        
                                        <h5 className="time" >Horários disponíveis dia {this.getDay()} {' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'}</h5>
                                        
                                        

                                        <div className="row just">
                                        {/* Mapeando as horas disponíveis */}
                                        { this.props.loading ?

                                            // Se está carregando
                                            <img className= "imagem App-logo" src={Logo}/> :

                                            this.props.erro ? 

                                            // Se deu erro no fetch
                                            <div>{this.props.erro}</div> :
                                            
                                            // Mapeando as horas
                                            // <div>{this.state.hours.toString()}</div>
                                            this.props.hours.map(
                                            hour=> <button key = {JSON.stringify(hour)}  className = "mincard" onClick = {() => this.props.handlePickHour(hour)}>
                                                {moment(hour.time).format('H:mm')}
                                            </button>)
                                        }
                                        </div>
                                </div>
                                
                    </div>
                                <div className="col-12 d-flex justify-content-center">
                                <button  className = "btnC" onClick = {() => this.props.changePage(true)}>
                                    {'Voltar'}
                                </button> 
                                </div>
            </div>
            <img className="bg-img" src={Bg }/>  
        </div> )
    }
}
