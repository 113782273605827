// Função retirada de https://www.devmedia.com.br/validar-cpf-com-javascript/23916
function validateCPF(strCPF:string) {

    if (!strCPF) return "CPF inválido"
    // Tirando sujeira do mask
    strCPF = strCPF.replaceAll(".", "")
    strCPF = strCPF.replace("-", "")
    console.log(strCPF)

    var Soma;
    var Resto;
    Soma = 0;
  if (strCPF == "00000000000") return "CPF inválido";

  for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return "CPF inválido";

  Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return "CPF inválido";

    // false porq n teve erro, false erros
    return false;
}

function validateName(name:string) {
  if (!name) return "Insira seu nome"

  let splited = name.split(' ')
  if (splited.length < 2) {
    return "Insira seu nome completo"
  }

  return false;
}


function validateEmail(email:string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase()))
    return false
  else
    return "E-mail Inválido"
}


function validatePhone(phone:string) {
  if (!phone) return "Telefone Inválido"
  return false
}

export {validateCPF, validateEmail, validatePhone, validateName}